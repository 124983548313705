
*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: 100%;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-breakpoint-only(lg){
    font-size: 80%;
  }
  @include media-breakpoint-only(xl){
    font-size: 85%;
  }
  @include media-breakpoint-only(xxl){
    font-size: 100%;
  }
}

body {
  @include font-size(16px);

  @include media-breakpoint-up(lg){
    @include font-size(20px);
  }

  background: $white;
  font-family: $contentfont;
  line-height: 1.3;
  font-weight: 400;
  color: $textcolor;
  overflow-x: hidden;
}

a {
  color: $linkcolor;
  text-decoration: underline;

  &:hover,
  &:active,
  &:focus {
    color: $linkcolor;
    text-decoration: none;
  }

  &:hover {
    color: $lightred;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: $textcolor;
  margin: 0;
}
h1, h2, h3, h4 {
  @include titlefont();
  color: $blue;
}

h1 {
  @include font-size(40px);
  line-height: 1.22;

  @include media-breakpoint-up(lg) {
    @include font-size(72px);
  }
}
h2 {
  position: relative;
  @include font-size(32px);
  line-height: 1.125;
  margin: 0 0 0.5em 0;
  padding-left: 1.3em;

  &:before {
    position: absolute;
    left: 0;
    top: 0.8em;
    @include icon('\e900');
    color: $red;
    font-size: 50%;
  }

  @include media-breakpoint-up(md){
    padding-left: 0;

    &:before {
      left: auto;
      right: calc(100% + 15px);
    }
  }

  @include media-breakpoint-up(lg) {
    @include font-size(64px);
  }
}
h3 {
  @include font-size(24px);
  color: $blue;
  line-height: 1.125;
  margin: 0 0 0.67em 0;

  @include media-breakpoint-up(lg) {
    @include font-size(48px);
  }
}
/*h4 {
  @include font-size(34px);
}
h5 {
  @include font-size(16px);
}*/

p {
  margin: 0 0 1.5em 0;
  line-height: 1.8;

  &.small {
    @include font-size(12px);
    color: tint( $black, 40% );
  }
}



.button {
  display: inline-block;
  padding: 15px 1em;
  border: none;
  border-radius: 0;
  background: $red;
  @include font-size(24px);
  color: $white;
  text-decoration: none;
  line-height: 1;
  margin: 0 0 6px 0;

  &:hover {
    background: $lightred;
  }
}

.buttons {
  a {
    @extend .button;
  }
}

.arrowlink {
  &:after {
    font-size: 60%;
    @include icon('\f054');
    display: inline-block;
    margin-left: 0.35em;
  }
}

.mail {
  white-space: nowrap;
  &:before {
    //font-size: 105%;
    @include icon('\f0e0');
    display: inline-block;
    margin: 0 0.5em 0 0;
    vertical-align: top;
  }
  @include media-breakpoint-up(md){
    @include knop();
  }
  @include media-breakpoint-down(sm){
    display: block;
    text-align: center;
  }
}

.tel {
  white-space: nowrap;
  &:before {
    //font-size: 105%;
    @include icon('\f095');
    display: inline-block;
    margin: 0 0.5em 0 0;
    vertical-align: top;
  }

  @include media-breakpoint-down(sm){
    @include knop();
  }
  @include media-breakpoint-down(sm){
    display: block;
    text-align: center;
  }
}


.arrowlist {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    position: relative;
    padding-left: 2em;
    margin-bottom: 0.8em;
    line-height: 1.4;
    &:before {
      position: absolute;
      left: 0;
      top: 0.5em;
      @include icon('\e900');
      color: $red;
      font-size: 60%;
    }
  }
}

.bx-wrapper {
  box-shadow: none;
  border: none;
  background: transparent;
  max-width: none !important;
  margin-top: 16px;

  @include media-breakpoint-up(lg) {
    margin-top: 32px;
  }
}
.bx-viewport {
  height: auto !important;
  @include media-breakpoint-only(md){
    $max-container-width : get-breakpoints("md");
    //padding-left: calc((100vw - #{$max-container-width}) / 2);
    padding-right: calc(100vw - #{$max-container-width});

  }
  @include media-breakpoint-only(lg){
    $max-container-width : get-breakpoints("lg");
    //padding-left: calc((100vw - #{$max-container-width}) / 2);
    padding-right: calc(100vw - #{$max-container-width});
  }
  @include media-breakpoint-only(xl){
    $max-container-width : get-breakpoints("xl");
    //padding-left: calc((100vw - #{$max-container-width}) / 2);
    padding-right: calc(100vw - #{$max-container-width});
  }
  @include media-breakpoint-only(xxl){
    $max-container-width : get-breakpoints("xxl");
    //padding-left: calc((100vw - #{$max-container-width}) / 2);
    padding-right: calc(100vw - #{$max-container-width});
  }
}

.projecten-slides,
.apparatuur-slides {

  @include media-breakpoint-only(md){
    $max-container-width : get-breakpoints("md");
    margin-left: calc((100vw - #{$max-container-width}) / 2);

  }
  @include media-breakpoint-only(lg){
    $max-container-width : get-breakpoints("lg");
    margin-left: calc((100vw - #{$max-container-width}) / 2);
  }
  @include media-breakpoint-only(xl){
    $max-container-width : get-breakpoints("xl");
    margin-left: calc((100vw - #{$max-container-width}) / 2);
  }
  @include media-breakpoint-only(xxl){
    $max-container-width : get-breakpoints("xxl");
    margin-left: calc((100vw - #{$max-container-width}) / 2);
  }
}


.slider-nav {
  padding-top: 20px;
  text-align: right;

  @include media-breakpoint-down(md){
    display: none;
  }
}
.next, .prev {
  display: inline-block;
  margin-left: 3px;
}

.bx-next {
  display: inline-block;
  width: 48px;
  height: 48px;
  text-align: center;
  text-decoration: none;
  @include font-size(28px);
  padding-top: 8px;
  color: $white;
  background-color: $lightblue;
  cursor: pointer;

  &:hover, &:active, &:focus {
    color: $white;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:after {
    @include icon('\f054');
  }
}
.bx-prev {
  @extend .bx-next;
  &:after {
    @include icon('\f053');
  }
}
