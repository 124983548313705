/* external fonts */
@import url('//fonts.googleapis.com/css?family=Roboto:400,700,700i');

/**
 * @license
 * MyFonts Webfont Build ID 3749055, 2019-04-18T04:40:17-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: UniversLTPro-65Bold by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/univers/pro-65-bold/
 * Copyright: Copyright &#x00A9; 2014 Monotype GmbH. All rights reserved.
 * Licensed pageviews: 750,000
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3749055
 *
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3934bf");

/* local fonts */
@include font-face('UniversLTPro-65Bold', '../fonts/3934BF_0_0', bold, normal);
@include font-face('icons', '../fonts/icomoon', normal, normal, false);
//@include font-face('ComicSans', '../Fonts/comic-sans');
//@include font-face('ComicSans', '../Fonts/comic-sans-bold', 'bold');

/* Fonts variables */
$contentfont: 'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
$titlefont: 'UniversLTPro-65Bold', $contentfont;
$iconfont: 'icons';

