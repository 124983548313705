
@mixin media($break) {
  @if $break == lg {
    @media (max-width: $screen-lg) { @content; }
  }
  @else if $break == md {
    @media (max-width: $screen-md) { @content; }
  }
  @else if $break == sm {
    @media (max-width: $screen-sm) { @content; }
  }
  @else if $break == xs {
    @media (max-width: $screen-xs) { @content; }
  }
  @else {
    @media ($break) { @content; }
  }
}

@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

.clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.no-bullets {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@mixin truncate($width) {
  max-width: $width;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin line-clamp($lines) {
  // http://caniuse.com/#search=line-clamp
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
}

@mixin input-placeholder {
  &.placeholder { @content; }
  &:-moz-placeholder { @content; }
  &::-moz-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
  &::-webkit-input-placeholder { @content; }
}

@function tint($color, $percentage) {
  @return mix(white, $color, $percentage);
}

@function shade($color, $percentage) {
  @return mix(black, $color, $percentage);
}

/* FONTS */
@mixin titlefont() {
  font-family: $titlefont;
  font-weight: bold;
  // text-transform: uppercase;
}

@mixin font-face($font-name, $file-name, $weight: normal, $style: normal, $woff2: true) {
  @if $woff2 {
    @font-face {
      font-family: quote($font-name);
      src: url($file-name + '.eot');
      src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
      url($file-name + '.woff2') format('woff2'),
      url($file-name + '.woff') format('woff'),
      url($file-name + '.ttf')  format('truetype'),
      url($file-name + '.svg##{$font-name}')  format('svg');
      font-weight: $weight;
      font-style: $style;
    }
  } @else {
    @font-face {
      font-family: quote($font-name);
      src: url($file-name + '.eot');
      src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
        //url($file-name + '.woff2') format('woff2'),
      url($file-name + '.woff') format('woff'),
      url($file-name + '.ttf')  format('truetype'),
      url($file-name + '.svg##{$font-name}')  format('svg');
      font-weight: $weight;
      font-style: $style;
    }
  }
}

@mixin icon($icon) {
  font-family: quote($iconfont);
  content: $icon;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  //line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

@mixin align($vertical: true, $horizontal: false, $position: relative) {
  @if $position {
    position: $position;
  }
  @if $vertical {
    top: 50%;
  }
  @if $horizontal {
    left: 50%;
  }

  @if $vertical and $horizontal {
    transform: translateX(-50%) translateY(-50%);
  } @else if $vertical {
    transform: translateY(-50%);
  } @else {
    transform: translateX(-50%);
  }
}

// use: max-width: get-breakpoints("g");
@function get-breakpoints($key: "md") {
  @return map-get($container-max-widths, $key);
}

/* PROJECT MIXINS */

@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > * {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin knop(){
  display: inline-block;
  padding: 15px 1em;
  border: none;
  border-radius: 0;
  background: $red;
  @include font-size(24px);
  color: $white !important;
  text-decoration: none;
  line-height: 1;
  margin: 0 0 6px 0;
  transition: background 0.15s ease-out;

  &:hover {
    background: $lightred;
  }
}