body {
  margin-top: 56px;

  @include media-breakpoint-up(md) {
    margin-top: 92px; // scrolled topbar height
  }
}

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 25;
  width: 100%;
  height: 56px;
  background: $white;
  border-bottom: 4px solid $red;
  transition: height 0.3s ease-in-out;

  @include media-breakpoint-up(md){
    height: 108px;
    border-bottom-width: 8px;
  }

  .topbar__logo {
    position: absolute;
    z-index: 5;
    top: 0;
    background-color: $white;
    border-bottom: 3px solid $white;
    transition: height 0.3s ease-in-out;

    width: 208px;
    height: 94px;
    padding: 0 23px 0 18px;
    left: calc(50% - 104px);


    @include media-breakpoint-up(md){
      width: 340px;
      padding: 0 23px 0 18px;
      height: 165px;
      left: calc(50% - 170px);
      border-bottom: 7px solid $white;
    }

    @include media-breakpoint-up(xl){
      width: 400px;
      height: 180px;
      padding: 0 46px 0 37px;
      left: calc(50% - 200px);
    }

    a {
      display: block;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      transition: width 0.3s ease-in-out;
      background: url("#{$img}meulatech_logo.png") no-repeat center top / 100% auto;
    }

  }

  .topbar__desktop,
  .topbar__mobile {
    height: 100%;
    > div {
      height: 100%;
    }
  }

  .topbar__desktop {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }


  .topbar__menu {
    @extend .no-bullets;
    height: 100%;

    li {
      height: 100%;
      display: inline-block;


      a {
        height: 100%;
        display: flex;
        align-items: center;
        align-content: stretch;
        padding: 10px 1.5em;
        @include titlefont();
        @include font-size(24px);
        color: $blue;
        text-decoration: none;

        &:hover {
          color: $lightblue;
        }
      }
    }
  }

  .topbar__menu-left {
    float: left;
    width: 50%;

    .topbar__menu {
      li:first-child a {
        padding-left: 0;
      }
    }
  }
  .topbar__menu-right {
    float: right;
    width: 50%;

    .topbar__menu {
      text-align: right;

      li:last-child a {
        padding-right: 0;
      }
    }
  }

  .topbar__mobile {
    display: block;
    padding: 0;
    @include media-breakpoint-up(lg) {
      display: none;
    }

    .menu-toggle {
      float: left;
      width: 80px;
      height: 100%;
      text-align: center;
      @include titlefont();
      @include font-size(12px);
      color: $black;
      text-decoration: none;

      a, div {
        display: block;
        height: 100%;
        padding-top: 5px;
        color: $black;
        text-decoration: none;
        cursor: pointer;

        &:before {
          display: block;
          @include icon('\f0c9');
          @include font-size(22px);
        }



      }
      div {
        &:after {
          content: 'Menu';
        }
      }

      @include media-breakpoint-only(md){
        @include font-size(14px);
        a, div {
          padding-top: 10px;
          &:before {
            @include font-size(28px);
          }
        }
      }
    }

    .contact-button {
      @extend .menu-toggle;
      float: right;
      a {
        &:before {
          @include icon('\f096');
        }
      }
    }
  }

  &.scrolled {

    /*.menu-toggle {
      a, div {
        padding-top: 6px;
        &:before {
          @include font-size(20px);
        }
      }
    }*/

    @include media-breakpoint-up(md){
      height: 92px;
    }

    .topbar__logo {
      height: 52px;
      a {
        width: 81%;
      }

      @include media-breakpoint-up(md) {
        height: 84px;
        a {
          width: 70%;
        }
      }
    }
  }



  .mobile-menu {
    position: relative;
    z-index: 2;
    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
    background-image: linear-gradient(-45deg, #8c2c24 0%, #3d396b 54%, #14344a 100%);
    width: 100%;
    height: 100vh;
    padding-top: 70px;
    color: $white;
    margin-top: 4px;
    @include media-breakpoint-only(md) {
      margin-top: 8px;
    }

    a {
      color: $white;
    }

    ul {
      @extend .no-bullets;
      margin: 0;
      padding: 0;

      li {
        margin: 0 0 12px 0;
        a {
          @include font-size(32px);
          @include titlefont();
          text-decoration: none;
        }
      }
    }

    .widget {
      color: $white;
      margin-top: 70px;
      h3 {
        color: $white;
        @include titlefont();
        @include font-size(20px);
      }
    }
  }

  &.expanded {

    .menu-toggle {
      div{
        &:before {
          @include icon('\f00d');
        }
        &:after {
          content: 'Sluit';
        }
      }
    }


    .mobile-menu {
      @include media-breakpoint-down(md) {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

}



.header {
  position: relative;
  z-index: 2;
  padding: 120px 0 50px 0;

  .header__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 90%;
    @include media-breakpoint-up(lg) {
      width: 58.333%;
    }
  }

  .crumbles {
    @include font-size(20px);
    text-decoration: none;

    @include media-breakpoint-up(lg) {
      @include font-size(24px);
    }

    a {
      @extend .arrowlink;
      margin-right: 5px;
      text-decoration: none;
      color: $textcolor;
    }

    .breadcrumb_last {
      display: none;
    }
  }

}

.hero {
  @extend .header;
  padding: 100px 0 100px 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  //background-blend-mode: multiply;

  .vimeo-wrapper {
    @include media-breakpoint-down(sm){
      display: none;
    }

    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;

    iframe {
      width: 100vw;
      height: 56.25vw;

      min-height: 100vh;
      min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .header__content {
    position: relative;
    z-index: 5;
    min-height:  45vh;
    @include media-breakpoint-up(lg) {
      min-height:  60vh;
    }
  }

  h1, .crumbles, .crumbles a {
    color: $white;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 40%;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #14344a 100%);
    opacity: .7;
  }

  &:after {
    position: absolute;
    z-index: 5;
    bottom: -20px;
    left: calc(50% - 15px);
    @include icon('\e900');
    @include font-size(22px);
    color: $red;
    transform: rotate(90deg);

    @include media-breakpoint-up(md){
      @include font-size(30px);
      left: calc(50% - 20px);
    }
  }
}

.content__toptext {
  position: relative;
  z-index: 3;
  margin-bottom: 48px;

  @include media-breakpoint-up(md){
    margin-bottom: 80px;
  }
  /*@include media-breakpoint-up(lg){
    margin-bottom: 120px;
  }
  @include media-breakpoint-up(xl){
    margin-bottom: 160px;
  }*/

  .no-content {
    @include media-breakpoint-up(lg){
      margin-bottom: -300px;
    }

  }


  .contact-block-small{
    @include media-breakpoint-up(lg){
      transform: translateY(-72px);
    }
  }
}

.hero + .post .content__toptext {
  margin-top: 50px;

  @include media-breakpoint-up(lg){
    margin-top: 128px;
    .contact-block-small {
      transform: translateY(-300px);
    }

  }


}

.content__quote {
  margin-bottom: 50px;
  @include media-breakpoint-up(md) {
    margin-bottom: 100px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 160px;
  }

  blockquote {
    text-align: center;
    @include font-size(24px);
    color: $blue;
    font-style: italic;
    font-weight: bold;
    line-height: 1.33;
    margin: 0 0 0.5em 0;

    &:before {
      content:'\201C';
    }
    &:after {
      content:'\201C';
    }

    @include media-breakpoint-up(md) {
      @include font-size(40px);
    }
    @include media-breakpoint-up(xl) {
      @include font-size(48px);
    }
  }

  .content__author-name {
    display: block;
    text-align: center;
    @include font-size(20px);
    color: #666666;

  }
}

.content__textmedia {
  position: relative;
  margin-bottom: 50px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  .container-fluid {
    margin-bottom: 16px;

    @include media-breakpoint-up(lg){
      margin: 0;
      padding: 0;
      position: absolute;
      left: 0;
      top: 0;
    }

  }

  .container {

    @include media-breakpoint-up(lg) {
      min-height: 480px;
    }
    @include media-breakpoint-up(xl) {
      min-height: 543px;
    }

    &.no-image {
      min-height: 0;
    }
  }

  figure {
    height: 220px;

    @include media-breakpoint-up(md){
      height: 320px;
    }
    @include media-breakpoint-up(lg) {
      height: 480px;
    }
    @include media-breakpoint-up(xl) {
      height: 543px;
    }

    img {
      width: 100%;
      height: 100% !important;
      object-fit: cover;
    }
  }

  .content__col {

    p:last-child {
      margin-bottom: 0;
      margin-bottom: 0;
    }

    ul {
      @extend .arrowlist;
    }
  }
}

.content__relatedlinks {
  margin-top: 30px;
  @include media-breakpoint-down(sm){
    white-space: nowrap;
    overflow-x: auto;
  }
  @include media-breakpoint-up(lg) {
    margin-top: 60px;
  }
}

.relatedlink {
  display: inline-block;
  width: 140px;
  vertical-align: top;
  text-decoration: none;
  margin: 0 9px 20px 0;
  @include font-size(16px);
  color: $red;
  @extend .arrowlink;

  figure {
    width: 100%;
    height: 80px;
    margin: 0 0 9px;

    img {
      max-width: 100%;
      object-fit: cover;
    }
  }

  @include media-breakpoint-up(md){
    @include font-size(20px);
    width: 160px;
    margin: 0 10px 30px 0;

    figure {
      height: 92px;
    }
  }

  @include media-breakpoint-up(xl){
    margin: 0 50px 40px 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    color: $lightred;
  }
}

.contact-block-small {
  position: relative;
  height: 302px;
  //width: 100%;
  background: #EEF3F8;
  margin-bottom: 30px;
  padding: 0 30px 0 30px;
  margin: 0 -15px;

  @include media-breakpoint-up(sm){
    margin: 0;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.25);
  }

  @include media-breakpoint-up(md){
    padding-right: calc(35% + 15px);

  }
  @include media-breakpoint-only(lg){
    padding-right: 30px;
  }
  @include media-breakpoint-only(xl){
    padding-right: 30px;
  }

  h5 {
    color: #666;
    @include font-size(20px);
    font-weight: bold;
    padding-top: 2em;
  }
  h4 {
    @include font-size(32px);
    color: $blue;
    margin: 0 0 1em 0;
  }
  a {
    display: block;
    text-decoration: none;
    color: #666;
    @include font-size(24px);

    &[href^=mailto]{
      @extend .mail;
      margin-bottom: 20px;
    }

  }
  figure {
    @include media-breakpoint-down(sm){
      display: none;
    }
    @include media-breakpoint-only(lg){
      display: none;
    }
    @include media-breakpoint-only(xl){
      display: none;
    }

    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 35%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

}

.contact-block-large {
  position: relative;
  background-color: #eef3f8;
  margin-bottom: 70px;


  @include media-breakpoint-up(lg){
    @include make-row();
    margin: 0 0 150px 0;
  }


  > figure {
    @include media-breakpoint-down(md){
      height: 50vw;
      img {
        object-position: 50% 10%;
      }
    }


    @include media-breakpoint-up(lg){
      @include make-col-ready();
      @include make-col(5);
      padding-left: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .contact-block__content {
    padding: 15px;

    @include media-breakpoint-up(lg) {
      @include make-col-ready();
      @include make-col(6);
      @include make-col-offset(1);
      padding-top: 4vw;
    }


    h5 {
      color: #666;
      @include font-size(20px);
      @include media-breakpoint-up(lg) {
        @include font-size(24px);
      }
      font-weight: bold;
      padding-top: 2em;
    }
    h4 {
      @include font-size(32px);
      @include media-breakpoint-up(lg) {
        @include font-size(48px);
      }
      color: $blue;
      margin: 0 0 1em 0;
    }

    .contact-block__text {
      margin-bottom: 50px;
      ul {
        @extend .arrowlist;
      }
    }

    a {
      display: inline-block;
      text-decoration: none;
      color: #666;
      @include font-size(24px);

      margin-bottom: 20px;
      & + a {
        @include media-breakpoint-up(md){
          padding-left: 1em;
        }

      }
    }
  }
}

.content__apparatuur {
  @include media-breakpoint-up(md){
    margin-bottom: 80px;
  }
  @include media-breakpoint-up(lg){
    margin-bottom: 120px;
  }
  @include media-breakpoint-up(xl){
    margin-bottom: 160px;
  }
}

.apparatuur-slides {
  @extend .clearfix;

  > div {
    max-width: 80vw;
  }
}

.apparaat-item {
  //float: left;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 30px;
  background-color: $white;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.33);

  width: 50%;
  max-width: 300px;
  min-width: 260px;

  > div {
    padding: 0 15px;
    min-height: 550px;
    @include media-breakpoint-up(md){
      min-height: 600px;
    }

  }

  @include media-breakpoint-up(md){
    max-width: 520px;
  }
  @include media-breakpoint-up(lg){
    max-width: 600px;

    > div {
      padding: 0 45px;
      min-height: auto;
    }
  }

  @include media-breakpoint-up(xl){
    @include aspect-ratio(3,4);
    width: calc((100% - 60px) / 2);
    max-width: 725px;
    max-height: 880px;


  }


  figure {
    //width: 100%;
    @include aspect-ratio(7,4);
    overflow: hidden;
    margin: 0 -15px 15px -15px;

    @include media-breakpoint-up(lg){
      margin: 0 -45px 40px -45px;
    }

    img {
      width: 100%;
      object-fit: cover;
    }

    &:before {
      position: relative;
      z-index: 3;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(20, 52, 74, 0.25) 100%);
    }
  }

  h4 {
    color: $blue;
    @include font-size(16px);
    line-height: 1.25;
    margin: 0 0 0.5em 0;

    @include media-breakpoint-up(md){
      @include font-size(32px);
    }
  }

  .apparaat-text {
    color: $textcolor;
    @include font-size(14px);
    line-height: 1.8;
    margin-bottom: 8px;

    @include media-breakpoint-up(md){
      @include font-size(20px);
    }
  }

  .apparaat-usps {
    color: #666;
    @include font-size(13px);
    margin-bottom: 13px;

    @include media-breakpoint-up(md){
      @include font-size(20px);
    }

    .apparaat-usp {
      @extend .clearfix;
      padding: 7px 0;
      border-bottom: 1px solid #bbb;

      &:last-child {
        border: none;
      }

      > div {


        &:first-child {
          font-weight: bold;

        }

        @include media-breakpoint-up(md){
          padding: 7px 0;
          float: left;
          width:50%;
          &:first-child {
            width:45%;
            margin-right: 5%;
          }
        }

      }
    }
  }

  .apparaat-link {
    display: inline-block;
    margin-bottom: 15px;
    @include font-size(13px);
    font-weight: bold;
    @extend .arrowlink;
    text-decoration: none;

    @include media-breakpoint-up(md){
      @include font-size(20px);
    }

    &:before {
      font-weight: normal;
      @include icon('\f1c1');
      display: inline-block;
      margin-right: 6px;
    }

    &:hover {
      color: $lightred;
    }

  }

}

.projecten-slides {
  display: flex;
  align-items: stretch;

  > div {
    max-width: 80vw;
  }
}


.project-link,
.expertise-link {
  display: block;
  text-decoration: none;
  background-color: $white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.33);
  transition: box-shadow 0.15s ease;
  height: calc(100% - 20px);

  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.33);
  }

  figure {
    @include aspect-ratio(7,4);
    overflow: hidden;
    img {
      width: 100%;
      object-fit: cover;
      // fix center image
      top: 50%;
      transform: translateY(-50%);
    }
  }
  h4 {
    color: $blue;
    @include font-size(16px);
    line-height: 1.25;
    margin: 0 0 0.5em 0;
    padding: 1em 1.2em 1.2em 1.2em;

    @include media-breakpoint-up(lg){
      @include font-size(32px);
    }
    //border-top: none;
  }
}

.content__projecten {
  .project-link,
  .expertise-link {
    margin: 0 12px 30px 12px;
    //max-width: 80vw;

    @include media-breakpoint-up(lg){
      margin: 0 15px 30px 15px;
      max-width: 725px;
    }
  }
}

.content__overview {
  margin-top: 80px;
  margin-bottom: 80px;

  .list {

    > div {
      @include make-col-ready();
      @include make-col(6);
      margin-bottom: 40px;

      @include media-breakpoint-up(lg){
        @include make-col(4);
        margin-bottom: 80px;
      }


      .project-link,
      .expertise-link {
        height: 100%;
      }
    }

  }
}

.content__image {
  margin: 80px 0;

  .container {
    figure {
      img {
        max-width: 100%;
      }
    }
  }

  > figure {
    overflow: hidden;
    height: 20vw;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}

.doormat {
  background-color: #eef3f8;
  padding-top: 32px;
  padding-bottom: 32px;

  @include media-breakpoint-up(md){
    padding-top: 40px;
  }
  @include media-breakpoint-up(lg){
    padding-top: 60px;
  }
  @include media-breakpoint-up(xl){
    padding-top: 80px;
  }


  .doormat__name {
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(lg){
      @include make-col(8);
    }
    @include media-breakpoint-up(xl){
      @include make-col(6);
    }

    h2 {
      @include font-size(32px);
      padding-left: 0;
      margin: 0 0 1.5em 0;
      &:before {
        content: none;
        display: none;
      }
    }
  }

  h4 {
    @include font-size(24px);
    line-height: 1.1;
    color: #BBBBBB;
    margin: 0 0 0.9em 0;

    a {
      color: $blue;
      text-decoration: none;
      @extend .arrowlink;

      &:hover {
        color: $lightblue;
      }
    }
  }


  .widget {
    @include media-breakpoint-up(md){
      margin-bottom: 60px;
    }

    @include font-size(20px);

    h6.widget-title {
      @include font-size(20px);
      color: #BBBBBB;
      margin-bottom: 8px;
    }

    ul {
      @extend .no-bullets;
      li {
        display: block;
        margin-bottom: 0.5em;

        a {
          color: $blue;
          text-decoration: none;
          line-height: 1.5;
          @extend .arrowlink;

          &:hover {
            color: $lightblue;
          }
        }
      }
    }

    a {
      display: block;
      color: #666666;
      text-decoration: none;
      &[href^=mailto]{
        @extend .mail;
      }
      &[href^=tel]{
        @extend .tel;
      }
    }
  }
}

.addressbar {
  position: relative;
  background: $blue;
  color: $white;
  font-weight: bold;
  @include font-size(20px);
  line-height: 1.5;
  padding: 2.5em 0;

  &:before {
    position: absolute;
    z-index: 5;
    top: -5px;
    left: calc(50% - 20px);
    @include icon('\e900');
    @include font-size(28px);
    color: $red;
    transform: rotate(90deg);
  }
  ul {
    @extend .no-bullets;
    text-align: center;
    li {
      margin-bottom: 0.4em;

      @include media-breakpoint-up(md){
        display: inline-block;
        vertical-align: text-bottom;
        margin-bottom: 0;

        &:after {
          content: '|';
          display: inline-block;
          margin: 0 12px 0 15px;
          //vertical-align: middle;
        }

        &:last-child {
          &:after {
            content: '';
            display: none;
          }
        }
      }

      a {
        text-decoration: none;
        color: $white;
      }

    }
  }
}
.copyright {
  padding: 2em 0;
  @include font-size(20px);

  .copyright__text {
    @include font-size(20px);
    color: $textcolor;

    @include make-col-ready();
    @include make-col(12);

    @include media-breakpoint-up(md){
      @include make-col(5);
    }
  }
  .copyright__menu {
    @include make-col-ready();
    @include make-col(12);

    ul {
      @extend .no-bullets;
      margin-top: 15px;
      li {
        color: $lightblue;
        margin: 0 0 0.4em 0;
        a {
          color: $lightblue;
          text-decoration: none;
        }
      }
    }

    @include media-breakpoint-up(md){
      text-align: right;
      @include make-col(7);

      ul {
        margin-top: 0;
        li {
          display: inline-block;
          margin: 0;

          &:after {
            content:'\2022';
            display: inline-block;
            margin: 0 8px 0 10px;
          }

          &:last-child:after {
            content: '';
            display: none;
          }
        }
      }
    }


  }
}
